textarea:focus,
input:focus {
  outline: none;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

::-moz-placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

:-ms-input-placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

::-ms-input-placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

::placeholder {
  font-style: italic;
  font-family: -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: 400;
}

/*Set default browser margins for headings to zero*/

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.scrollbar::-webkit-scrollbar-track { background-color: var(--grey-5);}

.scrollbar::-webkit-scrollbar-thumb { background-color: var(--grey-5);}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: var(--grey-5);
}

/* For firefox */

.scrollbar {
  scrollbar-color: var(--grey-5) var(--grey-5);
  scrollbar-width: thin;
}

a {
  text-decoration: none;
}

html {
  -webkit-font-smoothing: antialiased;
}

